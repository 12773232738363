import { useState } from 'react'
import useTitle from '../../hooks/useTitle'
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import ReportDistributor from './ReportDistributor'
import ReportCustomer from './ReportCustomer'
import ReportDivvy from './ReportDivvy'
import ReportDivvyServiceTech from './ReportDivvyServiceTech'
import ReportExpiration from './ReportExpiration'
import ReportSerialNumber from './ReportSerialNumber'
// import ReportTotals from './ReportTotals'
import ReportTotalsExpanded from './ReportTotalsExpanded'
import { useTheme } from '@mui/material'

const Reports = () => {
  useTitle('Reports | BILL Dashboard')
  const theme = useTheme()
  const [selectedOption, setSelectedOption] = useState('')
  return (
    <>
      <Typography
        variant="h1"
        sx={{ mb: 2 }}>
        Reports
      </Typography>
      <Box>
        <FormControl
          sx={{ width: 400, mb: 1 }}
          size="small">
          <InputLabel
            id="report-type"
            sx={{ backgroundColor: '#FFF', [theme.getColorSchemeSelector('dark')]: { backgroundColor: '#121212' }, px: 1 }}>
            Choose a report type
          </InputLabel>
          <Select
            labelId="report-type"
            variant="outlined"
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
            sx={{
              borderRadius: '5px'
            }}>
            <MenuItem value="distributors">Distributors</MenuItem>
            <MenuItem value="customers">Customers</MenuItem>
            <MenuItem value="divvy">Divvy</MenuItem>
            <MenuItem value="divvy-service-tech">Divvy-Service Tech</MenuItem>
            <MenuItem value="expiration">Expiration</MenuItem>
            <MenuItem value="serial">Serial Number(s)</MenuItem>
            <MenuItem value="expanded-totals">Totals Expanded</MenuItem>
            {/* <MenuItem value="totals">Totals</MenuItem> */}
          </Select>
        </FormControl>
      </Box>
      {selectedOption === 'distributors' && <ReportDistributor />}
      {selectedOption === 'customers' && <ReportCustomer />}
      {selectedOption === 'divvy' && <ReportDivvy />}
      {selectedOption === 'divvy-service-tech' && <ReportDivvyServiceTech />}
      {selectedOption === 'expiration' && <ReportExpiration />}
      {selectedOption === 'serial' && <ReportSerialNumber />}
      {/* {selectedOption === 'totals' && <ReportTotals />} */}
      {selectedOption === 'expanded-totals' && <ReportTotalsExpanded />}
    </>
  )
}
export default Reports
