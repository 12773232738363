import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const equipmentsAdapter = createEntityAdapter({})

const initialState = equipmentsAdapter.getInitialState()

export const equipmentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEquipments: builder.query({
      query: () => ({
        url: '/equipments',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError
        }
      }),
      providesTags: (result) => {
        if (result?.ids) {
          return [{ type: 'Equipment', id: 'LIST' }, ...result.ids.map((id) => ({ type: 'Equipment', id }))]
        } else return [{ type: 'Equipment', id: 'LIST' }]
      }
    }),
    getPaginatedEquipments: builder.query({
      query: ({ page = 1, limit = 20, sorting = [], searchTerm = '', isRecycled }) => {
        return {
          url: '/equipments',
          params: { page, limit, columnSort: sorting.length > 0 ? sorting[0].id : '', columnType: sorting.length > 0 ? sorting[0].desc : '', searchTerm, isRecycled },
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError
          }
        }
      },
      providesTags: (result) => {
        if (result?.ids) {
          return [{ type: 'Equipment', id: 'LIST' }, ...result.ids.map((id) => ({ type: 'Equipment', id }))]
        } else return [{ type: 'Equipment', id: 'LIST' }]
      }
    }),
    getFilteredEquipments: builder.query({
      query: (params) => {
        const { distributorName, distributorId, customerName, customerId, isRecycled, isRegistered, isExpired, shippingStartDate, shippingEndDate, registrationStartDate, registrationEndDate, excludeOmittedDistributors, reportType } = params
        return {
          url: '/equipments/reports',
          params: { distributorName, distributorId, customerName, customerId, isRecycled, isRegistered, isExpired, shippingStartDate, shippingEndDate, registrationStartDate, registrationEndDate, excludeOmittedDistributors, reportType },
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError
          }
        }
      }
    }),
    getFilteredExpiredEquipments: builder.query({
      query: ({ type, reportType, distributorId, distributorName }) => {
        return {
          url: '/equipments/expired',
          params: { type, reportType, distributorId, distributorName },
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError
          }
        }
      }
    }),
    getSerialNumbers: builder.query({
      query: ({ filteredNumbersArray, reportType }) => {
        return {
          url: '/equipments/serial-numbers',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ serialNumbers: filteredNumbersArray, reportType }),
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError
          }
        }
      }
    }),
    getEquipmentByUser: builder.query({
      query: ({ userId }) => {
        return {
          url: `/equipments/by-user/${userId}`,
          method: 'GET',
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError
          }
        }
      }
    }),
    addNewEquipment: builder.mutation({
      query: (initialEquipment) => ({
        url: '/equipments',
        method: 'POST',
        body: {
          ...initialEquipment
        }
      }),
      invalidatesTags: ['Equipment']
    }),
    updateEquipment: builder.mutation({
      query: (initialEquipment) => ({
        url: '/equipments',
        method: 'PATCH',
        body: {
          ...initialEquipment
        }
      }),
      invalidatesTags: ['Equipment']
    }),
    deleteEquipment: builder.mutation({
      query: ({ id }) => ({
        url: '/equipments',
        method: 'DELETE',
        body: { id }
      }),
      invalidatesTags: ['Equipment']
    })
  })
})

export const {
  useGetEquipmentsQuery,
  useGetPaginatedEquipmentsQuery,
  useLazyGetFilteredEquipmentsQuery,
  useLazyGetFilteredExpiredEquipmentsQuery,
  useGetEquipmentByUserQuery,
  useAddNewEquipmentMutation,
  useUpdateEquipmentMutation,
  useDeleteEquipmentMutation,
  useLazyGetSerialNumbersQuery
} = equipmentsApiSlice

// returns the query result object
export const selectEquipmentsResults = equipmentsApiSlice.endpoints.getEquipments.select()

// creates memoized selector
const selectEquipmentsData = createSelector(selectEquipmentsResults, (equipmentsResult) => equipmentsResult.data)

// getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllEquipments,
  selectById: selectEquipmentById,
  selectIds: selectEquipmentIds
  // Pass in a selector that returns the equipments slice of state
} = equipmentsAdapter.getSelectors((state) => selectEquipmentsData(state) ?? initialState)
