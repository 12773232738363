import { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { useModalContext } from './hooks/useModalContext'
import { useSearchContext } from './hooks/useSearchContext'
import { ToastContainer } from 'react-toastify'
import '@fontsource-variable/open-sans'

import Layout from './components/Layout'
import DashLayout from './components/DashLayout'

// Auth
import Login from './features/auth/Login'
import Welcome from './features/auth/Welcome'
import Prefetch from './features/auth/Prefetch'
import PersistLogin from './features/auth/PersistLogin'
import RequireAuth from './features/auth/RequireAuth'

// Pages
import EquipmentsPage from './features/equipments/EquipmentsPage'
import DistributorsPage from './features/distributors/DistributorsPage'
import CustomersPage from './features/customers/CustomersPage'
import UnitsPage from './features/units/UnitsPage'
import RecycledPage from './features/equipments/RecycledPage'
import UsersPage from './features/users/UsersPage'
import ExtUsersPage from './features/users/ExtUsersPage'
import LogsPage from './features/logview/LogsPage'

import { ROLES } from './config/roles'
import useTitle from './hooks/useTitle'

// modals
import ConfirmDeleteModal from './features/modal/ConfirmDeleteModal'
import DeleteModal from './features/modal/DeleteModal'
import InfoModal from './features/modal/InfoModal'
import CreateModal from './features/modal/CreateModal'
import UpdateModal from './features/modal/UpdateModal'
import PasswordModal from './features/modal/PasswordModal'

// forms
import CreateCustomerForm from './features/forms/CreateCustomerForm'
import CreateDistributorForm from './features/forms/CreateDistributorForm'
import CreateEquipmentForm from './features/forms/CreateEquipmentForm'
import CreateUnitForm from './features/forms/CreateUnitForm'
import CreateUserForm from './features/forms/CreateUserForm'
import CreateExtUserForm from './features/forms/CreateExtUserForm'
import UpdateDistributorForm from './features/forms/UpdateDistributorForm'
import UpdateEquipmentForm from './features/forms/UpdateEquipmentForm'
import UpdateUnitForm from './features/forms/UpdateUnitForm'
import UpdateUserForm from './features/forms/UpdateUserForm'
import UpdateExtUserForm from './features/forms/UpdateExtUserForm'
import UpdateCustomerForm from './features/forms/UpdateCustomerForm'

import Reports from './features/reports/Reports'

import ReactGA from 'react-ga4'

function App() {
  useTitle('Bill Dashboard')

  const { pageTitle, openModals } = useModalContext()
  const { handleClearSearch } = useSearchContext()

  ReactGA.initialize('G-6NVMK41CFN', { testMode: process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'stage' ? false : true })
  let location = useLocation()

  useEffect(() => {
    handleClearSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={<Layout />}>
          {/* public routes */}
          <Route
            index
            element={<Login />}
          />

          {/* Protected Routes */}
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}>
              <Route element={<Prefetch />}>
                <Route
                  path="dash"
                  element={<DashLayout />}>
                  <Route
                    path="welcome"
                    index
                    element={<Welcome />}
                  />

                  <Route element={<RequireAuth allowedRoles={[ROLES.superuser]} />}>
                    <Route path="users">
                      <Route
                        index
                        element={<UsersPage />}
                      />
                    </Route>
                    <Route path="db-access">
                      <Route
                        index
                        element={<ExtUsersPage />}
                      />
                    </Route>
                    <Route path="logs">
                      <Route
                        index
                        element={<LogsPage />}
                      />
                    </Route>
                  </Route>

                  <Route element={<RequireAuth allowedRoles={[ROLES.user, ROLES.superuser, ROLES.admin]} />}>
                    <Route path="equipments">
                      <Route
                        index
                        element={<EquipmentsPage />}
                      />
                    </Route>

                    <Route path="distributors">
                      <Route
                        index
                        element={<DistributorsPage />}
                      />
                    </Route>

                    <Route path="customers">
                      <Route
                        index
                        element={<CustomersPage />}
                      />
                    </Route>

                    <Route path="units">
                      <Route
                        index
                        element={<UnitsPage />}
                      />
                    </Route>

                    <Route path="recycled">
                      <Route
                        index
                        element={<RecycledPage />}
                      />
                    </Route>
                    <Route path="reports">
                      <Route
                        index
                        element={<Reports />}
                      />
                    </Route>
                  </Route>
                </Route>
                {/* End Dash */}
              </Route>
            </Route>
          </Route>
          {/* End Protected Routes */}
        </Route>
      </Routes>
      <ToastContainer
        position="bottom-right"
        theme="colored"
        hideProgressBar
        closeOnClick
      />
      {openModals.deleteModal && <DeleteModal />}
      {openModals.confirmDeleteModal && <ConfirmDeleteModal pageTitle={pageTitle} />}
      {openModals.infoModal && <InfoModal pageTitle={pageTitle} />}
      {openModals.createModal && (
        <CreateModal>
          <>
            {pageTitle === 'customer' && <CreateCustomerForm />}
            {pageTitle === 'equipment' && <CreateEquipmentForm />}
            {pageTitle === 'distributor' && <CreateDistributorForm />}
            {pageTitle === 'unit' && <CreateUnitForm />}
            {pageTitle === 'user' && <CreateUserForm />}
            {pageTitle === 'externaluser' && <CreateExtUserForm />}
          </>
        </CreateModal>
      )}
      {openModals.updateModal && (
        <UpdateModal>
          <>
            {pageTitle === 'customer' && <UpdateCustomerForm />}
            {pageTitle === 'distributor' && <UpdateDistributorForm />}
            {pageTitle === 'unit' && <UpdateUnitForm />}
            {pageTitle === 'user' && <UpdateUserForm />}
            {pageTitle === 'externaluser' && <UpdateExtUserForm />}
          </>
        </UpdateModal>
      )}
      {openModals.equipmentModal && <UpdateEquipmentForm pageTitle={pageTitle} />}
      {openModals.passwordModal && <PasswordModal />}
    </div>
  )
}

export default App
