import { useState } from 'react'
import { useLazyGetFilteredExpiredEquipmentsQuery } from '../equipments/equipmentsApiSlice'
import { useGetDistributorsQuery } from '../distributors/distributorsApiSlice'
import CustomWindowedSelect from '../../components/CustomWindowedSelect'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Unstable_Grid2'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import ReportsList from './ReportsList'
import BeatLoader from 'react-spinners/BeatLoader'
import { useTheme } from '@mui/material'
import { useColorScheme } from '@mui/material/styles'

const ReportExpiration = () => {
  const { mode } = useColorScheme()
  const theme = useTheme()
  const reportType = 'expiration'
  const [type, setType] = useState('')
  const [showTable, setShowTable] = useState(false)
  const [distributorId, setDistributorId] = useState('')
  const [distributorName, setDistributorName] = useState('')
  const { data: distributors } = useGetDistributorsQuery()
  const [sortModel, setSortModel] = useState([
    {
      field: 'expirationDate',
      sort: 'asc'
    }
  ])
  const [resetKey, setResetKey] = useState(0)
  const [getFilteredExpiredEquipment, { data, isLoading, isFetching, isError, error }] = useLazyGetFilteredExpiredEquipmentsQuery({ type })

  const handleDistributorNameChange = (e) => {
    if (e === null) {
      setDistributorId('')
      setDistributorName('')
      return
    }
    setDistributorId(e.value._id)
    setDistributorName(e.value.name)
  }

  const handleFilterSubmit = async (e) => {
    e.preventDefault()
    await getFilteredExpiredEquipment({ type, reportType, distributorId, distributorName })
    setShowTable(true)
  }

  const handleReset = (e) => {
    e.preventDefault()
    setResetKey((prev) => prev + 1)
    setType('')
    setDistributorId('')
    setDistributorName('')
    setShowTable(false)
  }

  const distributorOptions =
    distributors &&
    [...distributors]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((distributor) => {
        return { value: distributor, label: distributor.name, customLabel: `${distributor.address} ${distributor.city}, ${distributor.state} ${distributor.zip}` }
      })

  const currentDate = new Date()
  const currentMonth = currentDate.getMonth() // 0-based index (January = 0)
  const currentYear = currentDate.getFullYear()

  const monthOptions = Array.from({ length: 12 }, (_, i) => {
    const newDate = new Date(currentYear, currentMonth + i)
    const label = newDate.toLocaleString('default', { month: 'long', year: 'numeric' })
    const value = `${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(2, '0')}`

    return {
      label,
      value
    }
  })

  const columns = [
    {
      field: 'distributorName',
      headerName: 'Distributor',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      sortable: false,
      resizable: false,
      hideable: false
    },
    {
      field: 'shippingDate',
      headerName: 'Shipping Date',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => (params.row.expirationDate ? new Date(params.row.expirationDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      sortable: false,
      resizable: false,
      hide: true,
      visible: false
    },
    {
      field: 'installationDate',
      headerName: 'Installation Date',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => (params.row.installationDate ? new Date(params.row.installationDate) : null),
      valueFormatter: (params) => (params.value ? params.value.toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      sortable: true,
      resizable: false
    },
    {
      field: 'expirationDate',
      headerName: 'Expiration Date',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => (params.row.expirationDate ? new Date(params.row.expirationDate) : null),
      valueFormatter: (params) => (params.value ? params.value.toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      sortable: true,
      resizable: false,
      sortDescFirst: true,
      sortingFn: 'datetime'
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      valueGetter: (params) => (params.row.customer ? params.row.customer.name : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'customerAddress',
      headerName: 'Customer Address',
      valueGetter: (params) => (params.row.customer ? params.row.customer.address : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'customerAddress2',
      headerName: 'Customer Address 2',
      valueGetter: (params) => (params.row.customer ? params.row.customer.address2 : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'customerCity',
      headerName: 'Customer City',
      valueGetter: (params) => (params.row.customer ? params.row.customer.city : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'customerState',
      headerName: 'Customer State',
      valueGetter: (params) => (params.row.customer ? params.row.customer.state : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'customerZip',
      headerName: 'Customer Zip',
      valueGetter: (params) => {
        const zip = params?.row?.customer?.zip
        return zip ? zip.toString().padStart(5, '0') : ''
      },
      sortable: false,
      resizable: false
    },
    {
      field: 'customerPhone',
      headerName: 'Customer Phone',
      valueGetter: (params) => (params.row.customer ? params.row.customer.phone : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'customerEmail',
      headerName: 'Customer Email',
      valueGetter: (params) => (params.row.customer ? params.row.customer.email : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'purchaseOrder',
      headerName: 'Purchase Order #',
      sortable: false,
      resizable: false
    },
    {
      field: 'unitModel',
      headerName: 'Unit Model',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      sortable: false,
      resizable: false
    },
    {
      field: 'compressorHorsepower',
      headerName: 'Compressor Horsepower',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      sortable: false,
      resizable: false
    },
    {
      field: 'serialNumber',
      headerName: 'Serial #',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      sortable: false,
      resizable: false
    },
    {
      field: 'serialNumberOld',
      headerName: 'Old Serial Number',
      sortable: false,
      resizable: false
    }
  ]

  return (
    <>
      <Grid
        container
        spacing={2}
        marginTop={1}
        marginBottom={1}
        alignItems="flex-end">
        <Grid lg={3}>
          <Box>
            <FormControl
              fullWidth
              size="small">
              <InputLabel
                id="report-type"
                sx={{ backgroundColor: '#FFF', [theme.getColorSchemeSelector('dark')]: { backgroundColor: '#121212' }, px: 1 }}>
                Choose an expiration type
              </InputLabel>
              <Select
                labelId="report-type"
                variant="outlined"
                value={type}
                onChange={(e) => setType(e.target.value)}
                sx={{
                  borderRadius: '5fpx'
                }}>
                <MenuItem value="">-- None --</MenuItem>
                <MenuItem value="expired">Expired</MenuItem>
                <MenuItem value="thirtyDays">1 - 30 Days</MenuItem>
                <MenuItem value="sixtyDays">31 - 60 Days</MenuItem>
                <MenuItem value="ninetyDays">60 - 90 Days</MenuItem>
                {monthOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid lg={3}>
          <CustomWindowedSelect
            key={resetKey}
            options={distributorOptions}
            onChange={handleDistributorNameChange}
            titleLabel={
              <>
                Distributors - <small>leave blank for all distributors</small>
              </>
            }
            defaultValue={''}
          />
        </Grid>
        <Grid lg={3}>
          {!isFetching && (
            <Button
              variant="contained"
              sx={{ mr: 1 }}
              onClick={handleFilterSubmit}
              disabled={type || distributorId ? false : true}>
              Submit
            </Button>
          )}
          {isFetching && (
            <Button
              variant="contained"
              sx={{ mr: 1 }}
              onClick={handleFilterSubmit}
              disabled>
              Submit
            </Button>
          )}
          <Button
            variant="contained"
            color="black"
            onClick={handleReset}>
            Reset
          </Button>
        </Grid>
      </Grid>

      {isLoading || isFetching ? (
        <BeatLoader
          color={mode === 'light' ? '#222' : '#eFeFeF'}
          size={20}
          style={{ margin: '8px' }}
        />
      ) : isError ? (
        <Typography>{error.data.message}</Typography>
      ) : (
        <>
          {showTable && (
            <ReportsList
              equipments={data}
              columns={columns}
              reportType={reportType}
              hideColumns={{ shippingDate: false }}
              sortModel={sortModel}
              onSortModelChange={(model) => setSortModel(model)}
            />
          )}
        </>
      )}
    </>
  )
}

export default ReportExpiration
