export const convertRole = (role) => {
  switch (role) {
    case 'admin':
      return 'Admin'
    case 'superuser':
      return 'Super User'
    case 'user':
      return 'User'
    default:
      return 'Unknown'
  }
}
